import Carousel from "./carousel";

( function () {
    const carouselContainers = document.getElementsByClassName( "carousel" );
    const carousels = [];
    for ( let i = 0; i < carouselContainers.length; i++ ) {
        const carouselId = carouselContainers[ i ].getAttribute( "data-carousel-id" );
        carousels.push( new Carousel(
            carouselContainers[ i ],
            document.getElementById( "carousel-next-" + carouselId ),
            document.getElementById( "carousel-prev-" + carouselId )
        ) );
    }

} )();